import Newsletter from "../newsletter";

export default function Contact() {
    return (
        <>
            <div className='blog-container'>
                <div className='blog-header-wrapper'>
                    <p className='blog-header'>
                        Contact
                    </p>
                </div>

                <div className='blog-content-wrapper'>
                    <p className='bold'>E-mail</p>
                    <p className='contact-method'>
                        business@acceleration-engineering.com
                    </p>

                    <p className='bold'>Address</p>
                    <p className='contact-method'>
                        Gdansk, Poland <br/>
                        ul. Leborska 3b <br/>
                        80-386 Gdansk
                    </p>
                </div>
            </div>

            <Newsletter/>
        </>
    )
}