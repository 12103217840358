
export default function Support() {
    return (
        <>
            <div className='blog-container'>
                <div className='blog-header-wrapper'>
                    <p className='blog-header'>
                        Support
                    </p>
                </div>

                <div className='blog-content-wrapper'>
                    <p className='bold'>
                        Professional and quick support is critical to our customers' success.
                        We understand it and we respond swiftly to your support requests.
                    </p>

                    <p className='about-us'>
                        We’re available 10:00am - 6:00pm Central European Time (CET) on working days and, according to
                        possibility, outside working time. <br/>
                        In case of critical issues, we operate in an incident mode, meaning we work
                        as long as required until the issue is resolved.
                    </p>

                    <p className='bold'>Support channels</p>
                    <ul>
                        <li>
                            <a href="https://acceleration.atlassian.net/servicedesk/customer/portal/1" target='_blank'>
                                Acceleration Customer Support portal
                            </a>
                        </li>

                        <li>
                            email: support@acceleration-engineering.com
                        </li>
                    </ul>

                    <p className='about-us'>
                        See more details in&nbsp;<a href='https://acceleration-engineering.com/sla' target='_blank'>
                        Service Level Agreement.</a>
                    </p>
                </div>

            </div>
        </>
    )
}