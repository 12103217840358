
export default function SLA() {
    return (
        <>
            <div className='blog-container'>
                <div className='blog-header-wrapper'>
                    <p className='blog-header'>
                        Service Level Agreement
                    </p>
                </div>

                <div className='blog-content-wrapper eula'>
                    <p className='bold'>
                        Acceleration will provide online technical support in reference to Marketplace Products.
                    </p>

                    <p className='bold'>Support channels</p>
                    <ul>
                        <li>
                            <a href="https://acceleration.atlassian.net/servicedesk/customer/portal/1" target='_blank'>
                                Acceleration Customer Support portal
                            </a>
                        </li>

                        <li>
                            email: support@acceleration-engineering.com
                        </li>
                    </ul>

                    <p className='bold'>Business hours</p>
                    <ul>
                        <li>
                            Monday - Friday, 10:00am - 6:00pm CET
                        </li>

                        <li>
                            our support team is based in Gdansk, Poland
                        </li>

                        <li>
                            for all requests we respond in 24 hours, excluding national holidays in Poland.
                            We do our best to respond after working hours.
                        </li>
                    </ul>

                    <p className='bold'>Support for our products includes:</p>
                    <ul>
                        <li>
                            help with configuration of our products
                        </li>

                        <li>
                            discussing and solving problems related to our products
                        </li>
                        <li>
                            answering questions about our apps
                        </li>
                        <li>
                            discussing sales questions and new feature requests
                        </li>
                        <li>
                            support in English and Polish languages
                        </li>
                        <li>
                            Zoom calls for a faster communication, investigation and fix.
                        </li>
                    </ul>

                    <p className='bold'>Support policy</p>
                    <ul>
                        <li>
                            only unexpired licenses of the Marketplace Product are supported
                        </li>

                        <li>
                            only the most recent Marketplace Product version available on Atlassian Marketplace is supported.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}