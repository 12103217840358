
export default function Eula() {
    return (
        <>
            <div className='blog-container'>
                <div className='blog-header-wrapper'>
                    <p className='blog-header'>
                        EULA
                    </p>
                </div>

                <div className='blog-content-wrapper eula'>
                    <p className='bold'>I Introduction</p>

                    <ol>
                        <li>
                            This End User License Agreement ("EULA") outlines the terms under which the Provider offers
                            Subscriptions to its Marketplace Product, as described on the Atlassian Marketplace app
                            listing.
                            Atlassian is not a party to this EULA. Capitalized terms are defined either within the
                            context
                            of this document or in Section II.
                        </li>

                        <li>
                            The Customer and the Provider agree to this EULA upon the Customer's initial submission of
                            an
                            Order.
                        </li>

                        <li>
                            Orders may be placed through the Atlassian Marketplace.
                        </li>
                    </ol>

                    <p className='bold'>II Definitions</p>

                    <ol>
                        <li>
                            <span className='underline'>Authorized Users</span>: Individuals authorized by the Customer
                            to use the Marketplace Product on
                            their behalf.
                        </li>

                        <li>
                            <span className='underline'>Customer</span>: The entity or individual obtaining access to
                            the
                            Marketplace Product through
                            purchase or other means.
                        </li>

                        <li>
                            <span className='underline'>Customer Data</span>: Any data, content, or materials that the
                            Customer (including its Authorized
                            Users) uploads, posts, or otherwise makes available within the Marketplace Product. This
                            includes, but is not limited to, personal data, business information, files, messages, or
                            any
                            other type of information that the Customer or its Authorized Users provide through the use
                            of
                            the Marketplace Product. Customer Data remains the property of the Customer, and the
                            Provider
                            processes it in accordance with the terms outlined in the Privacy Policy and EULA, ensuring
                            its
                            proper handling, storage, and protection as required by applicable law.
                        </li>

                        <li>
                            <span className='underline'>Marketplace Product</span>: cloud application listed on the
                            Atlassian Marketplace
                            (https://marketplace.atlassian.com/)
                        </li>

                        <li>
                            <span className='underline'>Order</span>: An order by Customer for a Subscription that is
                            entered into through the Atlassian
                            Marketplace.
                        </li>

                        <li>
                            <span className='underline'>Permitted Use</span> means use of a Marketplace Product in
                            accordance with the applicable
                            Subscription
                            and Order.
                        </li>

                        <li>
                            <span className='underline'>Provider</span>: The organization offering and licensing the
                            Marketplace Product - Acceleration
                            Engineering with its registered seat in Gdansk, Leborska 3b, code 80-386 Gdansk, Poland, tax
                            number 5892005066.
                        </li>

                        <li>
                            <span className='underline'>Subscription</span>: The Customer's right to use the Marketplace
                            Product, as described in the
                            description of the Marketplace Product on the Atlassian Marketplace and specified in the
                            Order
                            for the Subscription.
                        </li>

                        <li>
                            <span className='underline'>Subscription Term</span>: The period during which the Customer
                            has authorized access to the Marketplace
                            Product.
                        </li>
                    </ol>

                    <p className='bold'>III License grant and obligations</p>
                    <ol>
                        <li>
                            The Provider grants the Customer a limited, non-transferable, non-exclusive license to
                            access
                            and use the Marketplace Product solely for its intended purpose.
                        </li>

                        <li>
                            The Customer agrees to use the Marketplace Product only in accordance with Permitted Use and
                            in compliance with the acceptable use regulations set forth by the Atlassian Marketplace, as
                            well as applicable law. The Customer further agrees to adhere to any guidelines or
                            restrictions
                            outlined by the Atlassian Marketplace regarding use of the Marketplace Product.
                        </li>

                        <li>
                            The Customer is prohibited from:

                            <ol className='list-nested'>
                                <li>
                                    modifying, reverse engineering, decompiling, or creating derivative works from the
                                    Marketplace Product;
                                </li>

                                <li>
                                    reselling, sublicensing, or sharing access to unauthorized parties;
                                </li>

                                <li>
                                    violating applicable laws or third-party rights using the Marketplace Product; or
                                </li>

                                <li>
                                    circumventing security measures or access controls implemented by the Provider.
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <p className='bold'>IV Types of License and Subscription Terms</p>
                    <ol>
                        <li>
                            License types:

                            <ol className='list-nested'>
                                <li>
                                    Evaluation License: A 30-day free trial period providing full access to all
                                    functionalities.
                                </li>

                                <li>
                                    Commercial License: Continued access after the Evaluation License period, contingent
                                    on subscription fees.
                                </li>
                            </ol>
                        </li>

                        <li>
                            In the framework of the Commercial License, the Customer may permit Authorized Users to use
                            the Marketplace Product on its behalf. The Customer is responsible for provisioning and
                            managing Authorized User accounts, ensuring their compliance with EULA and regulations of
                            Atlassian Marketplace, and for any actions of Authorized Users through the Marketplace
                            Product. The Customer may grant access to the Marketplace Product to multiple Authorized
                            Users across different devices, with all such users being subject to the same terms and
                            obligations.
                        </li>

                        <li>
                            Subscriptions and renewals are handled by the Atlassian Marketplace platform. Monthly and
                            annual billing is available. All fees include applicable taxes unless otherwise stated.
                        </li>
                    </ol>


                    <p className='bold'>V Privacy and Data Protection</p>
                    <ol>
                        <li>
                            Use of Customer Data: The Provider will process Customer Data in accordance with applicable
                            laws, including Regulation (EU) 2016/679 of the European Parliament and of the Council
                            (GDPR) and other relevant data protection regulations, solely to provide and maintain the
                            Marketplace Product and support in accordance with this EULA, Privacy Policy, and applicable
                            data protection laws. Customer Data may include personal data, business information, files,
                            messages, and other materials uploaded, posted, or made available within the Marketplace
                            Product by the Customer or its Authorized Users. Detailed information about data processing
                            is provided in the&nbsp;
                            <a href='https://acceleration-engineering.com/privacy' target='_blank'>Privacy Policy.</a>
                        </li>

                        <li>
                            Data Protection Compliance: The Customer must:

                            <ol className='list-nested'>
                                <li>
                                    comply with all applicable data protection and privacy laws;
                                </li>

                                <li>
                                    notify and obtain necessary consent from all users and data subjects regarding the
                                    processing of their personal data by the Provider and its partners.
                                </li>
                            </ol>
                        </li>

                        <li>
                            Data Processing Agreement (DPA): The Customer and the Provider will enter into a Data
                            Processing Agreement to formalize their respective responsibilities concerning the handling
                            of personal data and other Customer Data, if required.
                        </li>

                        <li>
                            Contact information: Upon purchase of a Marketplace Product, Atlassian Marketplace will
                            provide a Customer technical contact, which includes the full name, company name and the
                            email address. This information will enable the Provider to effectively communicate with and
                            support the Customer.
                        </li>

                        <li>
                            Security Measures: Provider will implement appropriate technical and organizational measures
                            to protect Customer Data from unauthorized access, use, or disclosure, ensuring its
                            confidentiality, integrity, and availability.
                        </li>

                        <li>
                            Legal Disclosures: Customer Data will only be disclosed to governmental or regulatory
                            authorities when required by law.
                        </li>
                    </ol>

                    <p className='bold'>VI Fees and Payment</p>
                    <ol>
                        <li>
                            All fees are payable as agreed in the Customer's order. Failure to pay fees when due may
                            result in suspension or termination of access to the Marketplace Product. The amount of fees
                            is specified in the order or the terms of the Atlassian Marketplace.
                        </li>
                    </ol>

                    <p className='bold'>VII Intellectual Property</p>
                    <ol>
                        <li>
                            Rights of the Provider <br/>
                            All ownership, title, and intellectual property rights in the Marketplace Product and
                            related documentation shall remain with the Provider. No rights other than those expressly
                            set out in the EULA are granted to the Customer.
                        </li>

                        <li>
                            Rights of the Customer <br/>
                            The Customer retains all intellectual property rights in its own data and materials provided
                            to the Provider. The Provider will not use such data except as required to provide the
                            Marketplace Product functionality and associated services.
                        </li>
                    </ol>

                    <p className='bold'>VIII Support and Service Levels</p>
                    <ol>
                        <li>
                            The Provider undertakes to provide support in accordance with any applicable support
                            policies specified in&nbsp;
                            <a href='https://acceleration-engineering.com/sla' target='_blank'>Service Level Agreement.</a>
                        </li>
                    </ol>

                    <p className='bold'>IX Disclaimer of warranties</p>
                    <ol>
                        <li>
                            The Marketplace Product is provided “AS IS” and “AS AVAILABLE” without warranties of any
                            kind, whether express, implied, or statutory, including but not limited to implied
                            warranties of merchantability or fitness for a particular purpose.
                        </li>

                        <li>
                            Except as expressly set out in EULA, each party disclaims all warranties, whether express,
                            implied, statutory, or otherwise, including warranties of merchantability, fitness for a
                            particular purpose, title, and non-infringement. These exclusions apply to the fullest
                            extent permitted by applicable law.
                        </li>
                    </ol>

                    <p className='bold'>X Limitations of Liability </p>
                    <ol>
                        <li>
                            To the maximum extent permitted by law, the total liability of the Provider to the Customer
                            for any claims shall not exceed the amount of the fees actually paid by the Customer for the
                            Marketplace Product. To the extent allowed by applicable regulations, the Provider disclaims
                            liability for any damages, including but not limited to indirect, incidental, or
                            consequential losses, loss of data, or loss of profits. This limitation shall not exclude or
                            limit liability in cases where such exclusion or limitation is prohibited by applicable
                            consumer protection laws. For consumers, the liability of the Provider is limited to the
                            extent permissible under Polish law.
                        </li>
                    </ol>

                    <p className='bold'>XI Indemnification</p>
                    <ol>
                        <li>
                            The Provider will indemnify the Customer against claims that the Marketplace Product
                            infringes the intellectual property rights of third-party, provided that the Customer
                            immediately notifies the Provider and cooperates in the defense.
                        </li>

                        <li>
                            The Customer shall indemnify the Provider against claims arising from misuse of the
                            Marketplace Product, non-compliance with applicable laws, or breach of this EULA. Any action
                            taken by the Customer in this regard must be consulted with the Provider.
                        </li>

                        <li>
                            Indemnification by the Provider requires:

                            <ol className='list-nested'>
                                <li>
                                    prompt notification by the Customer r of any claims,
                                </li>

                                <li>
                                    exclusive control of the legal remedies and settlement by the Provider,
                                </li>

                                <li>
                                    reasonable cooperation from the Customer to support the Provider's legal action.
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <p className='bold'>XII Term and Termination</p>
                    <ol>
                        <li>
                            The Evaluation License shall automatically terminate at the end of the evaluation period,
                            unless otherwise expressly agreed by the Parties.
                        </li>

                        <li>
                            All licenses under this EULA, except for the Evaluation License, remain in effect until
                            terminated.
                        </li>

                        <li>
                            This EULA shall remain in force for as long as the Customer has an active subscription to
                            use the Marketplace Product, unless terminated earlier in accordance with the provisions of
                            this EULA.
                        </li>

                        <li>
                            The Provider may terminate this EULA in the event of a material breach or non-compliance by
                            the Customer.
                        </li>

                        <li>
                            The decommissioning of the Marketplace Product by the Provider will have, as a consequence,
                            the termination of this EULA.
                        </li>

                        <li>
                            Upon termination of EULA, which occurs when the subscription expires, the Customer shall
                            immediately cease all use of the Marketplace Product.
                        </li>

                        <li>
                            Sections 9-11 of this Agreement shall remain in effect even after termination.
                        </li>
                    </ol>

                    <p className='bold'>XIII References</p>
                    <ol>
                        <li>
                            The Customer agrees that the name and logo of its company may be used by the Provider in
                            marketing and promotional materials, including on the website of the Provider and in
                            catalogues. The Provider undertakes to respect the brand image of the Customer and to use it
                            in accordance with the branding guidelines of the Customer. If the Customer wishes to opt
                            out of having its name or logo featured in such materials, it may submit a request via email
                            to support@acceleration-engineering.com
                        </li>
                    </ol>

                    <p className='bold'>XIV Miscellaneous</p>
                    <ol>
                        <li>
                            Governing Law and Disputes

                            <ol className='list-nested'>
                                <li>
                                    This EULA is governed by Polish law.
                                </li>

                                <li>
                                    Disputes unresolved through negotiation will be subject to the exclusive
                                    jurisdiction of Polish courts.
                                </li>
                            </ol>
                        </li>

                        <li>
                            Severability <br/>
                            Invalid or unenforceable provisions will not affect the validity of the
                            remaining terms.
                        </li>

                        <li>
                            Assignment <br/>
                            The Provider may assign rights and obligations under this EULA without the
                            consent of the Customer; any permitted assignee shall be bound by the terms and conditions
                            of this Agreement. The Customer assignment requires prior written consent from the Provider.
                        </li>

                        <li>
                            Force Majeure <br/>
                            The Provider is not liable for failure to perform obligations due to
                            unforeseen events beyond reasonable control, such as natural disasters, wars, strikes, or
                            government actions.
                        </li>

                        <li>
                            Entire Agreement <br/>
                            This EULA constitutes the entire agreement between the Provider and the
                            Customer, superseding prior communications. Customer agrees to the terms stated in Privacy
                            Policy and agrees to conclude Data Processing Agreement (if required).
                        </li>

                        <li>
                            Amendments <br/>
                            The Provider reserves the right, at its sole discretion, to change, add, or remove the terms
                            and conditions contained in this EULA at any time. Any changes will be notified to the
                            Customer via email or other appropriate means. Fourteen (14) days prior to changes,
                            customers will receive a notification containing the upcoming changes. After 14 days, the
                            updated EULA will be published on the website and the new changes will take effect. If the
                            Customer does not agree to the changes, the Customer shall stop using the Marketplace
                            Product and the contract will be terminated. In the event that any changes are made,
                            Provider will update the "Last Updated" date at the bottom of this EULA. The latest EULA
                            will always be available on the official website of the Provider.
                        </li>

                        <li>
                            Notices

                            <ol className='list-nested'>
                                <li>
                                    Communications to the Provider must be directed to
                                    support@acceleration-engineering.com.
                                </li>

                                <li>
                                    Notices to the Customer shall be sent to the email address or physical address
                                    provided at the time of registration and shall be deemed received 24 hours after
                                    sending by email or 14 days after being sent by postal mail, starting from the date
                                    of the first postal notification.
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <p className='bold'>Last Updated</p>
                    <p>February 5th, 2025</p>
                </div>
            </div>
        </>
    )
}